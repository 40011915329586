<template>
  <div id='author'>
    <el-card>
      <el-row :gutter="20" class="operate">
        <el-col :span="4">
          <el-input v-model="selectName" placeholder="请输入搜索的姓名" clearable></el-input>
        </el-col>
        <el-button @click="selectList" icon="el-icon-search" type="primary"></el-button>
        <el-button @click="showDialog = true, isAddFlag = true" type="primary">添加作者</el-button>
      </el-row>

      <el-table v-loading="loading" :data="tableData" style="width: 100%" stripe border>
        <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
        <el-table-column label="头像" width="100" class-name="avatar">
          <template #default="scope"><el-avatar :size="40" :src="scope.row.avatar"></el-avatar></template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="180"></el-table-column>
        <el-table-column label="性别" width="60">
          <template #default="scope">{{scope.row.sex | getSex(scope.row.sex)}}</template>
        </el-table-column>
        <el-table-column label="生日" width="150">
          <template #default="scope">{{scope.row.birth| isBirth(scope.row.birth)}}</template>
        </el-table-column>
        <el-table-column prop="age" label="年龄" width="60"></el-table-column>
        <el-table-column prop="desc" label="简介"></el-table-column>
        <el-table-column fixed="right" label="操作" width="210">
          <template #default="scope">
            <el-button @click="queryItem(scope.row.id)" type="primary" size="mini">查看</el-button>
            <el-button @click="openDialog(scope.row)" type="warning" size="mini">修改</el-button>
            <el-button @click="deleteItem(scope.row.id)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>

      <!-- 添加弹窗 -->
      <el-dialog @close="closeDialog(isAddFlag, 'addAuthorItem')" title="添加管理员" :visible.sync="showDialog" class="add_dialog">
        <el-form :model="form" ref="addAuthorItem" :rules="rules">
          <el-form-item label="头像" :label-width="formLabelWidth">
            <el-upload :action="baseURL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" class="avatar-uploader">
              <img v-if="form.avatar" :src="form.avatar" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="姓名" prop="name" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="性别" :label-width="formLabelWidth">
            <el-radio v-model="form.sex" label="0">未知</el-radio>
            <el-radio v-model="form.sex" label="1">男</el-radio>
            <el-radio v-model="form.sex" label="2">女</el-radio>
          </el-form-item>
          <el-form-item label="年龄" :label-width="formLabelWidth">
            <el-input v-model="form.age" autocomplete="off" type="number"></el-input>
          </el-form-item>
          <el-form-item label="生日" :label-width="formLabelWidth">
            <el-date-picker v-model="form.birth" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="简介" :label-width="formLabelWidth">
            <el-input v-model="form.desc" type="textarea" :autosize="{minRows: 2, maxRows: 4}" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog(isAddFlag, 'addAuthorItem')">取 消</el-button>
          <el-button v-if="isAddFlag" @click="addAuthor('addAuthorItem')" type="primary">确 定</el-button>
          <el-button v-else @click="editItem('addAuthorItem')" type="primary">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 查看弹窗 -->
      <el-dialog title="查看作者" :visible.sync="checkItem" center class="check_dialog">
        <div class="check_div">
          <div class="check_label">头像：</div>
          <div @click="getSrcList(checkData.avatar)" class="check_data">
             <el-image :src="checkData.avatar" :preview-src-list="srcList"></el-image>
          </div>
        </div>
        <div class="check_div">
          <div class="check_label">姓名：</div>
          <div class="check_data">{{checkData.name}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">性别：</div>
          <div class="check_data">{{checkData.sex | getSex(checkData.sex)}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">年龄：</div>
          <div class="check_data">{{checkData.age}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">生日：</div>
          <div class="check_data">{{checkData.birth | isBirth(checkData.birth)}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">简介：</div>
          <div class="check_data">{{checkData.desc}}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="checkItem = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {getAuthorList, addAuthorItem, queryAuthorItem, editAuthorItem, deleteAuthorItem} from '@api';

export default {
  data () {
    return {
      params: { // 请求分页参数
        page: 1,
        page_size: 10
      },
      tableData: [], // 表格数据
      loading: true, // 表格加载等待
      showDialog: false, // 是否显示弹窗
      isAddFlag: false, // 是否点击添加用户
      form: { // 添加表单
        avatar: '',
        name: '',
        sex: '0',
        age: '',
        birth: '',
        desc: '',
        status: 0,
        input_department_id: 0,
        input_user_id: 0
      },
      rules: {
        name: [
          {required: true, message: '请输入作者姓名', trigger: 'blur'}
        ],
      },
      formLabelWidth: '120px', // from表单lanel的宽度
      total: 0, // 数据总条数
      checkItem: false, // 查看每条的弹窗
      checkData: {}, // 查询每条时的数据
      selectName: '', // 搜索姓名
      baseURL: `${this.$store.state.config.baseURL}/upload`, // 上传图片地址
      srcList: [''], // 查看图片
    }
  },
  created() {
    this.getData(this.params);
  },
  methods:{
    selectList() { // 搜索姓名
      const {selectName} = this;
      this.params.name = selectName;
      this.getData(this.params);
    },
    addAuthor(refName) { // 添加作者
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        const {birth, sex, age} = this.form;
        const body = this.form;
        body.birth = this.$moment(birth).format('YYYY-MM-DD');
        body.sex = Number(sex);
        body.age = Number(age);
        addAuthorItem(body).then(() => {
          this.showDialog = false;
          this.$message.success('添加成功！');
          this.initParams();
          this.getData(this.params);
        }).catch(() => {
          const {birth} = this.form;
          if(birth == 'Invalid date') this.form.birth = '';
          this.form.sex = '0';
        });
      })
    },
    queryItem(id) { // 查询单条数据
      this.checkItem = true;
      this.checkData = {};
      queryAuthorItem(id).then(res => this.checkData = res.data);
    },
    editItem(refName) { // 修改单条数据
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        const {form, form: {id, birth, sex, age}} = this;
        const body = form;
        body.birth = this.$moment(birth).format('YYYY-MM-DD');
        body.sex = Number(sex);
        body.age = Number(age);
        const fields = Object.keys(body);
        const fields_1 = this.$removeItem(fields, 'create_time');
        const fields_2 = this.$removeItem(fields_1, 'update_time');
        body.fields = fields_2;
        console.log(body);
        editAuthorItem(id, body).then(() => {
          this.showDialog = false;
          this.$message.success('修改成功！');
          this.getData(this.params);
        });
      });
    },
    deleteItem(id) { // 删除单挑数据
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAuthorItem(id).then(() => {
          this.$message.success('删除成功！');
          this.getData(this.params);
        });
      }).catch(() => this.$message.info('已取消删除！'));
    },
    openDialog(item) { // 打开弹窗
      this.form = item;
      if(item.birth == 'Invalid date') this.form.birth = '';
      this.form.sex = item.sex + '';
      this.showDialog = true;
      this.isAddFlag = false;
    },
    closeDialog(flag, refName) { // 关闭弹窗
      if(flag) return this.showDialog = false;
      this.$refs[refName].resetFields();
      this.showDialog = false;
      this.initParams();
    },
    getSrcList(src) { // 查看图片
      this.srcList = [];
      this.srcList.push(src);
    },
    handleAvatarSuccess(res, file) { // 上传图片
      this.form.avatar = res.data.url;
    },
    beforeAvatarUpload(file) { // 上传图片
      const isImg = file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImg) this.$message.error('上传头像图片只能是 JPG/gif/jpeg/png 格式!');
      if (!isLt2M) this.$message.error('上传头像图片大小不能超过 2MB!');
      return isImg && isLt2M;
    },
    handleCurrentChange(val) { // 获取页数
      this.params.page = val;
      this.getData(this.params);
    },
    handleSizeChange(val) { // 获取每页条数
      this.params.page_size = val;
      this.getData(this.params);
    },
    getData(params) {
      this.loading = true;
      const {page_size} = this.params;
      getAuthorList(params).then(res => {
        this.loading = false;
        const {data, count} = res.data;
        this.total = count;

        if(count > 0 && data.length == 0) {
          const page = count / page_size;
          this.params.page = page;
          getAuthorList(this.params).then(result => this.tableData = result.data.data);
        }else {
          this.tableData = data;
        }
      })
    },
    initParams() { // 初始化数据
      this.form = {
        avatar: '',
        name: '',
        sex: '0',
        age: '',
        birth: '',
        desc: '',
        status: 0,
        input_department_id: 0,
        input_user_id: 0
      };
    }
  },
}
</script>

<style lang='less' scoped>
</style>